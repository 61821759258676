import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { Stub } from "@components/atoms/Stub"
import { MediaGallery } from "@components/media/MediaGallery"
import { RouteFlipped, RouteFlippedResponsiveImage } from "@components/motion/RouteFlipped"
import { Transition } from "@components/motion/Transition"
import { defineBlock, EditableText, useInlineEditableValue, usePostMetaEditor, usePostTitleEditor } from "eddev/blocks"
import { useEffect, useState } from "react"

export const meta: BlockMeta = {
  title: "Article Hero",
  description: "An image to be displayed in the article header, and on listing pages.",
  icon: "art",
  inserter: false,
  conditions: {
    multiple: false,
  },
  postMetaBlock: {
    postTypes: ["hall-of-fame", "article"],
    fieldName: "hero",
  },
  flags: {
    fullWidth: true,
    selfAnimates: true,
  },
}

export default defineBlock("article/article-hero", (props) => {
  if (props.heroBlockHidden && !env.admin) return null
  const introText = useInlineEditableValue("intro", "")

  const enableSideText = props.heroBlockHidden ? false : props.enableSideText && (env.admin || !!introText[0])

  if (!env.admin && !props.image) {
    return <div />
  }

  const helloStore = usePostMetaEditor("subtitle")

  return (
    <div className="grid-auto mb-4">
      <div className="col-span-12 border-2 border-[red]">
        <EditableText store={helloStore} defaultValue="HGelllo" appendOnEnter />
      </div>
      {enableSideText ? (
        <>
          <div className="col-span-12 md:col-span-8 ">
            <RouteFlippedResponsiveImage aspect={5 / 3} flipId={props.contentNode?.id!} {...props.image} />
            {props.image?.caption && <p className="type-body-s-roman text-grey mt-2" data-transition="fade" dangerouslySetInnerHTML={{ __html: props.image.caption }} />}
          </div>
          <div className="mt-7 md:mt-0 col-span-12 md:col-span-4" data-transition="fade">
            <EditableText store={introText} placeholder="Enter some optional intro text here" as="p" className="type-title-m-serif" />
          </div>
        </>
      ) : props.heroBlockHidden && env.admin ? (
        <>
          <div className="col-span-4 col-start-5">
            <Stub>Hidden</Stub>
            <ResponsiveImage aspect={5 / 3} fadeOnLoad={false} {...props.image} />
          </div>
        </>
      ) : (
        <>
          <div className="col-span-12 md:col-start-3 md:col-end-11 xl:col-start-4 xl:col-end-10">
            <RouteFlippedResponsiveImage aspect={5 / 3} flipId={props.contentNode?.id!} {...props.image} />
            {props.image?.caption && <p className="type-body-s-roman text-grey mt-2" data-transition="fade" dangerouslySetInnerHTML={{ __html: props.image.caption }} />}
          </div>
        </>
      )}
    </div>
  )
})
